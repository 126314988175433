import * as React from "react";
import Navbar from "../components/Navbar";
import NumericInput from 'react-numeric-input';
import Time from "../components/Time";
import { getColorScheme, getLanguage } from "../functions/helper";
import { Redirect } from '@reach/router';
import Trashcan from "../assets/trashcan.svg";
import Disclaimer from "../components/Disclaimer";
import ApexChart from "../components/ApexChart";

// markup
const IndexPage = () => {
  const parseDosages = (dosages) => {
    return dosages?.map(dosage => {
      return {
        date: new Date(dosage.date),
        dosage: dosage.dosage,
        id: dosage.id
      }
    }) ?? [];
  };

  const [firstVisit, setFirstVisit] = React.useState(false);
  const [standardDosage, setStandardDosage] = React.useState(1.2);
  const [colorScheme, setColorScheme] = React.useState(getColorScheme('light'));
  const [substance, setSubstance] = React.useState('GBL');
  const [mode, setMode] = React.useState('simple');
  const [dosages, setDosages] = React.useState([]);
  const [language, setLanguage] = React.useState(getLanguage('EN'));
  const [message, setMessage] = React.useState('');

  const noteDosage = () => {
    setDosages([
      ...dosages,
      {
        id: Math.floor(Math.random() * 900000000 + 100000000).toString(),
        date: new Date(),
        dosage: standardDosage
      }
    ]);
  };

  const resetDosages = () => {
    localStorage?.setItem('dosages', JSON.stringify([]));
    setDosages([]);
  }

  const deleteDosage = (id) => {
    setDosages(dosages.filter(dosage => dosage.id !== id));
  }

  const handleChange = (e) => {
    setStandardDosage(parseFloat(document.querySelector('#dosage').value));
  }

  React.useEffect(() => {
    if (localStorage.getItem('language') === null || localStorage.getItem('substance') === null || localStorage.getItem('mode') === null) {
      setFirstVisit(true);
    }

    if (localStorage.getItem('colorScheme')) {
      setColorScheme(getColorScheme(localStorage.getItem('colorScheme')));
    }

    if (localStorage.getItem('language')) {
      setLanguage(getLanguage(localStorage.getItem('language')));
    }

    if (localStorage.getItem('substance')) {
      setSubstance(localStorage.getItem('substance'));
    }

    if (localStorage.getItem('mode')) {
      setMode(localStorage.getItem('mode'));
    }

    if (localStorage.getItem('dosages')) {
      setDosages(parseDosages(JSON.parse(localStorage.getItem('dosages'))));
    }

    if (localStorage.getItem('message')) {
      setMessage(localStorage.getItem('message'));
    }
  }, []);

  React.useEffect(() => {
    if (dosages.length !== 0) {
      localStorage?.setItem('dosages', JSON.stringify(dosages));
    }
  }, [dosages]);

  React.useEffect(() => {
    setStandardDosage(substance == 'GBL' ? 1.2 : substance == 'GHB' ? 2.0 : substance == 'BDO' ? 1.8 : 0);
  }, [substance]);

  if (firstVisit) {
    return (<Redirect noThrow to="/setup" />);
  }

  return (
    <main className="min-h-screen flex flex-col" style={{ color: colorScheme.textColor, backgroundColor: colorScheme.backgroundColor }}>
      <Navbar />

      <div className="max-w-screen-sm w-full mx-auto px-4 mb-10 flex-grow">
        <div style={{ backgroundColor: colorScheme.containerBackgroundColor }} className="my-4 sm:my-10 p-6 sm:p-10">
          <div className="grid grid-cols-1 gap-8">
            <div className="">
              <h2>{language.NOTE_DOSAGE}</h2>
              {
                mode === 'advanced' && message && message !== "" && (
                  <div style={{ backgroundColor: colorScheme.backgroundColor }} className="mb-4 p-4">
                    <b>Message for you from you:</b> <br />
                    {message}
                  </div>
                )
              }
              <div className="flex gap-x-4 items-center justify-center">
                <NumericInput
                  id="dosage"
                  onChange={handleChange}
                  className="w-full text-black"
                  style={{ input: { height: '2rem' } }}
                  step={0.1}
                  precision={2}
                  value={standardDosage}
                  mobile={true}
                  format={(num) => num + 'mL'}
                />
                <button
                  style={{ backgroundColor: colorScheme.buttonBackgroundColor }}
                  className="px-4 w-1/2 hidden sm:block"
                  onClick={noteDosage}
                >{language.NOTE}</button>
              </div>

              <button
                style={{ backgroundColor: colorScheme.buttonBackgroundColor }}
                className="block sm:hidden w-full mt-2 py-2"
                onClick={noteDosage}
              >{language.NOTE_DOSAGE}</button>

              <button
                className="hidden sm:block mt-6 w-full text-white"
                style={{ backgroundColor: colorScheme.red }}
                onClick={resetDosages}
              >{language.RESET}</button>
            </div>

            <div>
              <h2 className="flex justify-between">
                <span>{language.DOSAGE_HISTORY}</span>
                <button
                  className="block sm:hidden text-white px-4"
                  style={{ backgroundColor: colorScheme.red }}
                  onClick={resetDosages}
                >{language.RESET}</button>
              </h2>
              <div>
                {
                  dosages.length > 0 ?
                    dosages.sort((a, b) => b.date.getTime() - a.date.getTime()).map((dosage, index) =>
                      <div
                        key={`dosage-${index}`}
                        className={`flex justify-between py-1 pl-6 pr-3 sm:px-3 text-xl -mx-6 sm:mx-0 ${index % 2 ? 'bg-white/10' : 'bg-black/10'}`}
                      >
                        <Time className="whitespace-nowrap" date={dosage.date} />
                        <div className="flex gap-x-4">
                          <span>
                            {dosage.dosage}mL
                          </span>

                          <Trashcan
                            className="h-7 filter-red hover:opacity-80 transition-opacity cursor-pointer"
                            onClick={() => deleteDosage(dosage.id)}
                          />
                        </div>
                      </div>
                    ) :
                    (
                      <div
                        className="p-4 text-center"
                        style={{ backgroundColor: colorScheme.lightContainerBackgroundColor }}
                      >
                        {language.NONE}
                      </div>
                    )
                }
              </div>
            </div>
          </div>
        </div>

        {
          mode === 'advanced' && (
            <div style={{ backgroundColor: colorScheme.containerBackgroundColor }} className="my-4 sm:my-10 p-6 sm:p-10">
              {
                substance === 'GBL' ?
                  <ApexChart dosages={dosages} textColor={colorScheme.textColor} /> :
                  <div>
                    <h2>GGGGraph</h2>
                    <p>
                      The graph only works for GBL at the moment.
                    </p>
                  </div>
              }
            </div>
          )
        }
      </div>

      <Disclaimer />
    </main>
  )
}

export default IndexPage
