import React from "react";
import ReactApexChart from "react-apexcharts";

class ApexChart extends React.Component {
  constructor(props) {
    super(props);
    this.textColor = props.textColor;
    this.handleGraphStuff(props.dosages);
  };

  handleGraphStuff(propsDosages) {
    this.setState({ dosages: propsDosages });

    const chartData = [];
    const dosages = propsDosages.reverse().map(dosage => {
      return [dosage.date.getTime(), dosage.dosage]
    });

    if (dosages.length !== 0) {
      const minTime = dosages[0][0];
      const maxTime = dosages[dosages.length - 1][0] + 10800000 * 2; // + 6 hours
      let currentDosage = 0;    // adds up over time in the graph
      let currentLevel = null;  // specifies how much is in the body right now

      for (let currentTime = minTime - 60000; currentTime < maxTime; currentTime += 60000) {
        currentDosage -= currentDosage * 0.01111111111;
        while (dosages.length > 0 && currentTime >= dosages[0][0]) {
          currentDosage += dosages[0][1];
          dosages.shift();
        }

        if (currentLevel === null && currentTime >= new Date().getTime()) {
          console.log("current is " + currentDosage);
          currentLevel = currentDosage;
        }

        chartData.push([currentTime, currentDosage]);
      }
    }

    this.currentLevel = this.currentLevel;
    this.chart = {
      series: [{
        name: 'G-Level',
        data: chartData
      }],
      options: {
        chart: {
          type: 'area',
          stacked: false,
          height: 350,
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
          },
          toolbar: {
            autoSelected: 'zoom'
          }
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
        },
        title: {
          text: 'G in tha Body',
          align: 'left',
          style: {
            color: this.textColor
          }
        },
        colors: ['#9C27B0'],
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100]
          },
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return val.toFixed(2);
            },
            style: {
              colors: this.textColor
            }
          },
          title: {
            text: 'G-Level',
            style: {
              color: this.textColor
            }
          },
          min: 0.0
        },
        xaxis: {
          type: 'datetime',
          labels: {
            style: {
              colors: this.textColor
            }
          }
        },
        tooltip: {
          shared: false,
          y: {
            formatter: function (val) {
              return val.toFixed(2) + 'mL'
            }
          }
        }
      },
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dosages !== this.props.dosages) {
      this.handleGraphStuff(nextProps.dosages);
    }
  }

  render() {
    return (
      <div id="chart">
        <h2>GGGGraph</h2>
        <p className="mb-4">
          <strong>How does it work?</strong> I'm no scientist, so it assumes a half-life of 45 minutes. Every body is different, and I almost failed math, so do not only rely on this graph.
        </p>
        <ReactApexChart style={{ color: 'white' }} options={this.chart.options} series={this.chart.series} type="area" height={350} />
      </div>


    );
  }
}

export default ApexChart;
