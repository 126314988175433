import { Link } from "gatsby";
import * as React from "react";

const getTimeString = (currentDate, date) => {
  let d = Math.abs(date - currentDate) / 1000;                           // delta
  const r = {};                                                                // result
  const s = {                                                                  // structure
    day: 86400,
    hour: 3600,
    minute: 60
  };

  Object.keys(s).forEach(function (key) {
    r[key] = Math.floor(d / s[key]);
    d -= r[key] * s[key];
  });

  return (
    <span className={r.day === 0 && r.hour === 0 ? 'text-red-700 font-bold' : ''}>
      {
        r.day > 0 ?
          `${r.day} d. ${r.hour} hrs. ${r.minute} min.` :
          (
            r.hour > 0 ?
              `${r.hour} hrs. ${r.minute} min.` :
              `${r.minute} min.`
          )
      }
    </span>
  );
};

const Time = ({ date, className = "" }) => {
  const [currentDate, setCurrentDate] = React.useState(new Date());

  React.useEffect(() => {
    const timer = setTimeout(() => setCurrentDate(new Date()), 1000);
    return () => clearTimeout(timer);
  }, [currentDate]);

  return (
    <div className={className}>
      {getTimeString(currentDate, new Date(date))}
    </div>
  )
};

export default Time;
